import React from "react"
import IntlText from "../../components/common/intl-text-2"
import Text from "../../components/common/text"
import AndroidIcon from "../../components/icons/AndroidIcon"
import AppleIcon from "../../components/icons/AppleIcon"
import WebIcon from "../../components/icons/WebIcon"
import Layout from "../../components/layout"
import AccountImage from "../../components/projects/fudex-images/account"
import AccountMobileImage from "../../components/projects/fudex-images/account-mobile"
import BackgroundImage from "../../components/projects/fudex-images/background"
import ConfigImage from "../../components/projects/fudex-images/config"
import ConfigMobileImage from "../../components/projects/fudex-images/config-mobile"
import FeaturesImage from "../../components/projects/fudex-images/features"
import FeaturesMobileImage from "../../components/projects/fudex-images/features-mobile"
import IconImage from "../../components/projects/fudex-images/icon"
import Icon1Image from "../../components/projects/fudex-images/icon-1"
import Icon2Image from "../../components/projects/fudex-images/icon-2"
import Icon3Image from "../../components/projects/fudex-images/icon-3"
import Icon4Image from "../../components/projects/fudex-images/icon-4"
import Illustration1Image from "../../components/projects/fudex-images/illustration-1"
import Illustration2Image from "../../components/projects/fudex-images/illustration-2"
import Illustration3Image from "../../components/projects/fudex-images/illustration-3"
import LogoImage from "../../components/projects/fudex-images/logo"
import LogoNewImage from "../../components/projects/fudex-images/logo-new"
import LogoOldImage from "../../components/projects/fudex-images/logo-old"
import ScratchImage from "../../components/projects/fudex-images/scratch"
import Screenshot1Image from "../../components/projects/fudex-images/screenshot-1"
import Screenshot2Image from "../../components/projects/fudex-images/screenshot-2"
import Screenshot3Image from "../../components/projects/fudex-images/screenshot-3"
import Screenshot4Image from "../../components/projects/fudex-images/screenshot-4"
import TicketsImage from "../../components/projects/fudex-images/tickets"
import TicketsMobileImage from "../../components/projects/fudex-images/tickets-mobile"
import ProjectContainer from "../../components/projects/project-container"
import ProjectContent from "../../components/projects/project-content"
import ProjectIntro from "../../components/projects/project-intro"

const tags = ["Product Thinking", "UX / UI", "Frontend", "Mobile"]

const stores = [
  {
    store: "android",
    icon: <AndroidIcon />,
    link: "https://play.google.com/store/apps/details?id=com.fudex.app",
  },
  {
    store: "apple",
    icon: <AppleIcon />,
    link: "https://itunes.apple.com/us/app/fudex/id1292104053?mt=8",
  },
  {
    store: "web",
    icon: <WebIcon />,
    link: "https://app.fudex.com.ar",
  },
]

const technologies = "React, React Native"

const HowWeHelp = () => {
  return (
    <div className="row how-we-help">
      <div className="col-12 col-lg-6 left-column">
        <IntlText id="fudex.body2" component="h1" />
      </div>
      <div className="col-12 col-lg-6 right-column">
        <IntlText id="fudex.body3" component="h2" />
      </div>
    </div>
  )
}

const WhatWeDo = () => {
  return (
    <div className="row what-we-do">
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="technologies" />
        <Text>
          <strong>{technologies}</strong>
        </Text>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="platforms" />
        <Text>
          <IntlText id="fudex.platforms" component="strong" />
        </Text>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="services" />
        <Text>
          <IntlText id="fudex.services" component="strong" />
        </Text>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Text>
          <IntlText id="projectContent.explore" />
        </Text>
        <div className="store-icons">
          {stores.map((value, key) => (
            <a
              key={key}
              href={value.link}
              target="_blank"
              rel="noopener noreferrer"
              title={value.store}
            >
              {value.icon}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

const LogoRedesign = () => {
  return (
    <div className="project-redesign">
      <div className="row">
        <div className="col-4">
          <Text id="projectContent.oldLogo" />
          <LogoOldImage className="redesign-image" />
        </div>
        <div className="col-4">
          <Text id="projectContent.newLogo" />
          <LogoNewImage className="redesign-image" />
        </div>
        <div className="col-4">
          <Text id="projectContent.icon" />
          <IconImage className="redesign-image" />
        </div>
      </div>
    </div>
  )
}

const Banner = () => {
  return (
    <div className="project-banner fudex">
      <div className="row">
        <div className="col-6 col-md-3">
          <Icon1Image className="icon-image" />
        </div>
        <div className="col-6 col-md-3">
          <Icon2Image className="icon-image" />
        </div>
        <div className="col-6 col-md-3">
          <Icon3Image className="icon-image" />
        </div>
        <div className="col-6 col-md-3">
          <Icon4Image className="icon-image" />
        </div>
      </div>
    </div>
  )
}

const Illustrations = () => {
  return (
    <div className="illustration-container">
      <div className="row">
        <div className="col-4">
          <Illustration1Image className="illustration-image" />
        </div>
        <div className="col-4">
          <Illustration2Image className="illustration-image" />
        </div>
        <div className="col-4">
          <Illustration3Image className="illustration-image" />
        </div>
      </div>
    </div>
  )
}

const Screenshots = () => {
  return (
    <div className="screenshots-container fudex">
      <div className="images">
        <Screenshot1Image className="screenshot-img" />
        <Screenshot2Image className="screenshot-img" />
        <Screenshot3Image className="screenshot-img" />
        <Screenshot4Image className="screenshot-img" />
      </div>
    </div>
  )
}

const FudexPage = () => {
  return (
    <Layout
      pageTitle="Projects"
      title="Fundex´s software development challenge - Sysgarage"
      description="Check out the software development project for Fundex that our company has successfully tackled. ​Let us show you how we can help!"
      hideHeader
      noMargin
      hideContact
    >
      <ProjectContainer>
        <div className="sg-container">
          <ProjectIntro
            tags={tags}
            logo={<LogoImage className="logo-image" />}
            textId="fudex.body1"
            background={<BackgroundImage className="background-image" />}
          />
          <HowWeHelp />
          <WhatWeDo />
        </div>
        <ProjectContent
          titleId="fudex.body4"
          descriptionId="fudex.body5"
          image={<ScratchImage />}
          imageMobile={<ScratchImage />}
        />
        <ProjectContent
          titleId="fudex.body6"
          descriptionId="fudex.body7"
          image={<ConfigImage />}
          imageMobile={<ConfigMobileImage />}
          imageFullWidth
        />
        <ProjectContent
          titleId="fudex.body8"
          descriptionId="fudex.body9"
          image={<AccountImage />}
          imageMobile={<AccountMobileImage />}
        />
        <ProjectContent
          titleId="fudex.body10"
          descriptionId="fudex.body11"
          image={<FeaturesImage />}
          imageMobile={<FeaturesMobileImage />}
        />
        <ProjectContent titleId="fudex.body12">
          <LogoRedesign />
        </ProjectContent>
        <Banner />
        <ProjectContent titleId="fudex.body13">
          <Illustrations />
        </ProjectContent>
        <ProjectContent
          titleId="fudex.body14"
          descriptionId="fudex.body15"
          image={<TicketsImage />}
          imageMobile={<TicketsMobileImage />}
          imageFullWidth
        />
        <ProjectContent titleId="fudex.body16" descriptionId="fudex.body17">
          <Screenshots />
          <div className="blank-space" />
        </ProjectContent>
      </ProjectContainer>
    </Layout>
  )
}

export default FudexPage
