import React from "react"
import { FUDEX_ALT } from "../../../constants/Constants"

const image = require("../../../images/fudex/illustration1.png")

const Illustration1Image = props => {
  return <img src={image} alt={FUDEX_ALT} {...props} />
}

export default Illustration1Image
