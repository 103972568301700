import React from "react"
import { FUDEX_ALT } from "../../../constants/Constants"

const image = require("../../../images/fudex/icon-3.png")

const Icon3Image = props => {
  return <img src={image} alt={FUDEX_ALT} {...props} />
}

export default Icon3Image
