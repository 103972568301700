import React from "react"
import { FUDEX_ALT } from "../../../constants/Constants"

const image = require("../../../images/fudex/illustration2.png")

const Illustration2Image = props => {
  return <img src={image} alt={FUDEX_ALT} {...props} />
}

export default Illustration2Image
