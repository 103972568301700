import React from "react"
import { FUDEX_ALT } from "../../../constants/Constants"

const image = require("../../../images/fudex/screenshot-1.png")

const Screenshot1Image = props => {
  return <img src={image} alt={FUDEX_ALT} {...props} />
}

export default Screenshot1Image
