import React from "react"
import { useTheme } from "styled-components"
import { FUDEX_ALT } from "../../../constants/Constants"

const image = require("../../../images/fudex/icon.png")
const imageDark = require("../../../images/fudex/icon-dark.png")

const IconImage = props => {
  const { isDark } = useTheme();
  if (isDark) {
    return <img src={imageDark} alt={FUDEX_ALT} {...props} />
  }
  return <img src={image} alt={FUDEX_ALT} {...props} />
}

export default IconImage
