import React from "react"
import { FUDEX_ALT } from "../../../constants/Constants"

const image = require("../../../images/fudex/config.png")

const ConfigImage = props => {
  return <img src={image} alt={FUDEX_ALT} {...props} />
}

export default ConfigImage
