import React from "react"
import { FUDEX_ALT } from "../../../constants/Constants"

const image = require("../../../images/fudex/icon-1.png")

const Icon1Image = props => {
  return <img src={image} alt={FUDEX_ALT} {...props} />
}

export default Icon1Image
