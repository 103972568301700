import React from "react"
import { useTheme } from "styled-components";
import { FUDEX_ALT } from "../../../constants/Constants"

const image = require("../../../images/fudex/logo-old.png")
const imageDark = require("../../../images/fudex/logo-old-dark.png")

const LogoOldImage = props => {
  const { isDark } = useTheme();
  if (isDark) {
    return <img src={imageDark} alt={FUDEX_ALT} {...props} />
  }
  return <img src={image} alt={FUDEX_ALT} {...props} />
}

export default LogoOldImage
